import styled from '@emotion/styled'
import { Image, Props as ImageProps } from 'app/components/Common/Image'
import { theme } from 'app/theme'
import { rgba } from 'emotion-rgba'
import { Link } from 'gatsby'
import React, { memo } from 'react'
import { LazyLoadComponent } from 'react-lazy-load-image-component'

export interface Props {
  description?: string
  image?: ImageProps
  title?: string
  URL?: string
}

export const Banner = memo(function Banner({
  description,
  image,
  title,
  URL,
}: Props) {
  if (!title || !URL) {
    return null
  }

  return (
    <Container>
      <Wrapper to={URL}>
        {image ? (
          <LazyLoadComponent>
            <Image {...image} />
          </LazyLoadComponent>
        ) : null}

        {title ? (
          <Title className="banner-title">
            <Label>{title}</Label>
          </Title>
        ) : null}

        {description ? (
          <Description
            className="banner-description"
            dangerouslySetInnerHTML={{ __html: description }}
          />
        ) : null}
      </Wrapper>
    </Container>
  )
})

const Container = styled.section`
  background: ${({ theme }) => theme.colors.variants.neutralLight2};
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  padding: 7.5rem 4.167vw 0;
  text-align: center;
`

const Wrapper = styled(Link)`
  display: block;
  height: 51.5vh;
  overflow: hidden;
  position: relative;
  &:hover {
    &:before {
      height: 100%;
    }
    img {
      transform: scale(1.1);
    }
    .banner-title {
      bottom: 6.25rem;
      span {
        &:after {
          right: 0;
        }
      }
    }
    .banner-description {
      bottom: 3.75rem;
      opacity: 1;
    }
  }
  &:before {
    content: '';
    width: 100%;
    height: 50%;
    backface-visibility: hidden;
    background: linear-gradient(
      ${rgba(theme.colors.variants.neutralDark1, 0)},
      ${rgba(theme.colors.variants.neutralDark1, 0.4)}
    );
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 2;
    transition: 0.25s ease-in-out;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    transition: 0.25s ease-in-out !important;
  }
`

const Title = styled.h3`
  width: 100%;
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 1.75rem;
  position: absolute;
  bottom: 2.5rem;
  left: 0;
  text-transform: uppercase;
  transition: 0.3s ease-in-out;
  z-index: 3;
`

const Label = styled.span`
  position: relative;
  &:after {
    content: '';
    height: 0.125rem;
    background: ${({ theme }) => theme.colors.variants.neutralLight4};
    position: absolute;
    bottom: 0;
    right: 100%;
    left: 0;
    transition: 0.3s ease-in-out;
  }
`

const Description = styled.div`
  width: 100%;
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.9375rem;
  line-height: 1.4375rem;
  opacity: 0;
  position: absolute;
  bottom: 1.25rem;
  left: 0;
  transition: 0.3s ease-in-out;
  z-index: 3;
`
